<template>
  <div>
    <div id="header">
      <HeaderPage />
    </div>

    <div id="content">
      <section>
        <table border="0" width="100%" height="400px" style="color: white">
          <tr>
            <td width="40%" align="center">
              <img
                src="./../assets/Cartoonlogo.png"
                width="250px"
                height="350px"
              />
            </td>
            <td width="60%" align="center">
              <h2>About Our Company</h2>
              <p>
                Lorem ipsum commot ammet dollo sevver allows you to send
                requests to a remote data set, like querying a database. The
                response will then be delivered back to your own application in
                a standardized format. This is very helpful when you’re building
                an app or pulling metrics for reporting, because it means you
                can focus on presenting information in a unique or useful
                manner, rather than developing the underlying data set.For
                example, most weather apps get their weather forecast data from
                a weather API, rather than building weather stations themselves.
              </p>

              <p>
                Lorem ipsum commot ammet dollo sevver allows you to send
                requests to a remote data set, like querying a database. The
                response will then be delivered back to your own application in
                a standardized format. This is very helpful when you’re building
                an app or pulling metrics for reporting, because it means you
                can focus on presenting information in a unique or useful
                manner, rather than developing the underlying data set.For
                example, most weather apps get their weather forecast data from
                a weather API, rather than building weather stations themselves.
              </p>
            </td>
          </tr>
        </table>

        <table border="0" width="100%" height="150px" style="color: white">
          <tr>
            <td width="33%" align="center">
              Our vision
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Et
                ullam rem ipsam minus debitis dignissimos ad assumenda
                voluptatem sit distinctio!
              </p>
            </td>
            <td width="33%" align="center">
              Our mission
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Et
                ullam rem ipsam minus debitis dignissimos ad assumenda
                voluptatem sit distinctio!
              </p>
            </td>
            <td width="33%" align="center">
              Core value
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Et
                ullam rem ipsam minus debitis dignissimos ad assumenda
                voluptatem sit distinctio!
              </p>
            </td>
          </tr>
        </table>
      </section>
    </div>

    <div id="footer">
      <FooterPage />
    </div>
  </div>
</template>

<script>
import HeaderPage from "../components/HeaderPage.vue";
import FooterPage from "../components/FooterPage.vue";

export default {
  name: "AboutPage",
  components: {
    HeaderPage,
    FooterPage,
  },
};
</script>

<style scoped>
#header {
  top: 0px;
  left: 0px;
  height: 260px;
  width: 100%;
  background-color: aquamarine;
  position: absolute;
}

#content {
  top: 190px;
  left: 0px;
  height: 700px;
  width: 100%;
  background-color: chocolate;
  position: absolute;
}

#footer {
  top: 890px;
  left: 0px;
  height: 64px;
  width: 100%;
  background-color: aquamarine;
  position: absolute;
}

h2 {
  font-size: 60pt;
  color: white;
  text-align: center;
}
</style>
