<template>
  <div id="header">
    <HeaderPage />
  </div>

  <div id="content">
    <section>
      <h2 style="color: white">Teen Titans Go Cartoon</h2>

      <table border="0" width="100%" height="400px" style="color: white">
        <tr>
          <td width="50%" align="center">
            <iframe
              width="560"
              height="505"
              src="https://www.youtube.com/embed/2XPoq09uovY?si=WFJk3ZcSgr-dj70v"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </td>
          <td width="50%" align="center">
            <h2>Teen Titans Go Details</h2>
            Teen Titans Go! is an American animated television series developed
            by Aaron Horvath and Michael Jelenic for Cartoon Network. It
            premiered on April 23, 2013, and is based on DC Comics' fictional
            superhero team the Teen Titans. The series was announced following
            the popularity of DC Nation's New Teen Titans shorts. <br /><br />
            The production companies of the series are DC Entertainment and
            Warner Bros. Animation, with the animation outsourced to Canada at
            Copernicus Studios and Bardel Entertainment. Sporting a different
            animation style, Teen Titans Go! serves as a comedic standalone
            spin-off with little to no continuity to the original Teen Titans
            series (although some references are included as comedic fan
            service) or any other media in the DC Comics franchise. <br /><br />
            Many DC characters make cameo appearances and are referenced in the
            background. The original principal voice cast returns to reprise
            their respective roles. This series explores what the Titans do when
            they are hanging out around the tower.
            <h3>watch here</h3>

            <router-link to="/looney"
              ><button class="button">Order Here</button></router-link
            >
          </td>
        </tr>
      </table>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <table border="0" width="100%" height="250px">
        <caption style="font-size: 40pt; color: white">
          <b> Episodes Available</b>
        </caption>
        <tr>
          <td width="16%" align="center">
            <h3 style="color: white">Season 1</h3>
            <img
              src="../assets/teenseason1.jpeg"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 1 Ep1</h3>
            <img
              src="../assets/teenseason1ep1.jpeg"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 2</h3>
            <img
              src="../assets/teenseason2.jpeg"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 2 Ep1</h3>

            <img
              src="../assets/teenseason2ep1.jpeg"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 3</h3>
            <img
              src="../assets/teenseason3.jpeg"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 3 Ep1</h3>
            <img
              src="../assets/teenseason3ep1.jpeg"
              width="200px"
              height="200px"
            />
          </td>
        </tr>
      </table>
    </section>
  </div>

  <div id="footer">
    <FooterPage />
  </div>
</template>

<script>
import HeaderPage from "../components/HeaderPage.vue";
import FooterPage from "../components/FooterPage.vue";

export default {
  name: "DetailsPage",
  components: {
    HeaderPage,
    FooterPage,
  },
};
</script>

<style scoped>
#header {
  top: 0px;
  left: 0px;
  height: 260px;
  width: 100%;
  background-color: aquamarine;
  position: absolute;
}

#content {
  top: 250px;
  left: 0px;
  height: 1250px;
  width: 100%;
  background-color: chocolate;
  position: absolute;
}

#footer {
  top: 1500px;
  left: 0px;
  height: 64px;
  width: 100%;
  background-color: aquamarine;
  position: absolute;
}

h2 {
  font-size: 35pt;
  color: white;
  text-align: center;
}

button {
  background-color: white;
  border: 0;
  border-radius: 5%;
  width: 185px;
  height: 40px;
}
</style>
