<template>
  <div id="header">
    <HeaderPage />
  </div>

  <div id="content">
    <section>
      <h2 style="color: white">Adventure Time Cartoon</h2>

      <table border="0" width="100%" height="400px" style="color: white">
        <tr>
          <td width="50%" align="center">
            <iframe
              width="560"
              height="505"
              src="https://www.youtube.com/embed/v_am43iRm-A?si=RMsYL31lz2qJI8v_"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </td>
          <td width="50%" align="center">
            <h2>Adventure Time Details</h2>
            Adventure Time[c] is an American fantasy animated television series
            created by Pendleton Ward and co-produced by Frederator Studios for
            Cartoon Network. The series follows the adventures of a boy named
            Finn (Jeremy Shada) and his best friend and adoptive brother Jake
            (John DiMaggio)—a dog with the power to change size and shape at
            will. Finn and Jake live in the post-apocalyptic Land of Ooo, where
            they interact with Princess Bubblegum (Hynden Walch), the Ice King
            (Tom Kenny), Marceline (Olivia Olson), BMO (Niki Yang), and others.
            <br /><br />
            The series is based on a 2007 short film that aired on Nicktoons.
            After the short became a viral hit on the Internet, Nickelodeon's
            executives passed on its option before Cartoon Network commissioned
            a full-length series from Fred Seibert and Ward, which was previewed
            on March 11, 2010. The same year, the series premiered on Cartoon
            Network on April 5, and it ended its eight-year run on September 3,
            2018. The series drew inspiration from a variety of sources,
            including the fantasy role-playing game Dungeons & Dragons and video
            games. It was produced using hand-drawn animation; action and
            dialogue for episodes are decided by storyboarding artists based on
            rough outlines. <br /><br />
            Because each episode took roughly eight to nine months to complete,
            multiple episodes were worked on concurrently. The cast members
            recorded their lines in group recordings, and the series regularly
            employed guest actors for minor and recurring characters. Each
            episode runs for about eleven minutes; pairs of episodes are often
            telecast to fill half-hour program slots. Cartoon Network announced
            on September 29, 2016, that the series would conclude in 2018, after
            the airing of its tenth season.
            <h3>watch here</h3>

            <router-link to="/looney"
              ><button class="button">Order Here</button></router-link
            >
          </td>
        </tr>
      </table>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <table border="0" width="100%" height="250px">
        <caption style="font-size: 40pt; color: white">
          <b> Episodes Available</b>
        </caption>
        <tr>
          <td width="16%" align="center">
            <h3 style="color: white">Season 1</h3>
            <img
              src="../assets/adventureseason1.png"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 1 Ep1</h3>
            <img
              src="../assets/adventureseason1ep1.jpeg"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 2</h3>
            <img
              src="../assets/adventureseason2.jpeg"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 2 Ep1</h3>

            <img
              src="../assets/adventureseason2ep1.jpeg"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 3</h3>
            <img
              src="../assets/adventureseason3.jpeg"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 3 Ep1</h3>
            <img
              src="../assets/adventureseason3ep1.jpeg"
              width="200px"
              height="200px"
            />
          </td>
        </tr>
      </table>
    </section>
  </div>

  <div id="footer">
    <FooterPage />
  </div>
</template>

<script>
import HeaderPage from "../components/HeaderPage.vue";
import FooterPage from "../components/FooterPage.vue";

export default {
  name: "DetailsPage",
  components: {
    HeaderPage,
    FooterPage,
  },
};
</script>

<style scoped>
#header {
  top: 0px;
  left: 0px;
  height: 260px;
  width: 100%;
  background-color: aquamarine;
  position: absolute;
}

#content {
  top: 250px;
  left: 0px;
  height: 1350px;
  width: 100%;
  background-color: chocolate;
  position: absolute;
}

#footer {
  top: 1550px;
  left: 0px;
  height: 64px;
  width: 100%;
  background-color: aquamarine;
  position: absolute;
}

h2 {
  font-size: 35pt;
  color: white;
  text-align: center;
}

button {
  background-color: white;
  border: 0;
  border-radius: 5%;
  width: 185px;
  height: 40px;
}
</style>
