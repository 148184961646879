<template>
  <div>
    <div id="header">
      <HeaderPage />
    </div>

    <div id="content">
      <section>
        <table border="0" width="100%" height="400px" style="color: white">
          <tr>
            <td width="40%" align="center">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/hxOApe1P9dM?si=H4yFghZRlQoF-3F_"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </td>
            <td width="60%" align="center">
              <h3 style="font-size: 40px">About Our Company</h3>
              <p>
                Lorem ipsum commot ammet dollo sevver allows you to send
                requests to a remote data set, like querying a database. The
                response will then be delivered back to your own application in
                a standardized format. This is very helpful when you’re building
                an app or pulling metrics for reporting, because it means you
                can focus on presenting information in a unique or useful
                manner, rather than developing the underlying data set.For
                example, most weather apps get their weather forecast data from
                a weather API, rather than building weather stations themselves.
              </p>

              <p>
                Lorem ipsum commot ammet dollo sevver allows you to send
                requests to a remote data set, like querying a database. The
                response will then be delivered back to your own application in
                a standardized format. This is very helpful when you’re building
                an app or pulling metrics for reporting, because it means you
                can focus on presenting information in a unique or useful
                manner, rather than developing the underlying data set.For
                example, most weather apps get their weather forecast data from
                a weather API, rather than building weather stations themselves.
              </p>
            </td>
          </tr>
        </table>
        <br />
        <br />
        <br />
        <br />
        <br />
        <table border="0" width="100%" height="700px">
          <caption style="font-size: 25pt; color: white">
            <b>Latest Houses Available</b>
          </caption>
          <tr>
            <td>
              <table border="0" width="100%">
                <tr>
                  <td align="center" style="font-size: 25pt; color: white">
                    <b> Scooby_Doo </b>
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    <img
                      class="tf"
                      src="./../assets/scooby.jpeg"
                      width="200px"
                      height="250px"
                    />
                  </td>
                </tr>
                <tr>
                  <td align="center" style="font-size: 20pt; color: white">
                    <b> Get Here</b>
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    <router-link to="/details"
                      ><button class="button">View Details</button></router-link
                    >
                  </td>
                </tr>
              </table>
            </td>
            <td>
              <table border="0" width="100%">
                <tr>
                  <td align="center" style="font-size: 25pt; color: white">
                    <b> Mr Bean </b>
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    <img
                      class="tf"
                      src="./../assets/mrbean.png"
                      width="200px"
                      height="250px"
                    />
                  </td>
                </tr>
                <tr>
                  <td align="center" style="font-size: 20pt; color: white">
                    <b> Get Here </b>
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    <router-link to="/details2"
                      ><button class="button">View Details</button></router-link
                    >
                  </td>
                </tr>
              </table>
            </td>
            <td>
              <table border="0" width="100%">
                <tr>
                  <td align="center" style="font-size: 25pt; color: white">
                    <b> Powerpuff Girls </b>
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    <img
                      class="tf"
                      src="./../assets/powergirls.jpeg"
                      width="200px"
                      height="250px"
                    />
                  </td>
                </tr>
                <tr>
                  <td align="center" style="font-size: 20pt; color: white">
                    <b> Get Here </b>
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    <router-link to="/details3"
                      ><button class="button">View Details</button></router-link
                    >
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <table border="0" width="100%">
                <tr>
                  <td align="center" style="font-size: 25pt; color: white">
                    <b> Tom and Jerry </b>
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    <img
                      class="tf"
                      src="./../assets/tom.jpeg"
                      width="200px"
                      height="250px"
                    />
                  </td>
                </tr>
                <tr>
                  <td align="center" style="font-size: 20pt; color: white">
                    <b> Get Here </b>
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    <button class="button">View Details</button>
                  </td>
                </tr>
              </table>
            </td>
            <td>
              <table border="0" width="100%">
                <tr>
                  <td align="center" style="font-size: 25pt; color: white">
                    <b> Ben 10 </b>
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    <img
                      class="tf"
                      src="./../assets/ben.jpeg"
                      width="200px"
                      height="250px"
                    />
                  </td>
                </tr>
                <tr>
                  <td align="center" style="font-size: 20pt; color: white">
                    <b> Get Here </b>
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    <router-link to="/details10"
                      ><button class="button">View Details</button></router-link
                    >
                  </td>
                </tr>
              </table>
            </td>
            <td>
              <table border="0" width="100%">
                <tr>
                  <td align="center" style="font-size: 25pt; color: white">
                    <b> SpongeBob </b>
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    <img
                      class="tf"
                      src="./../assets/sponge.jpeg"
                      width="200px"
                      height="250px"
                    />
                  </td>
                </tr>
                <tr>
                  <td align="center" style="font-size: 20pt; color: white">
                    <b> Get Here </b>
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    <button class="button">View Details</button>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </section>
    </div>

    <div id="footer">
      <FooterPage />
    </div>
  </div>
</template>
<script>
import HeaderPage from "../components/HeaderPage.vue";
import FooterPage from "../components/FooterPage.vue";

export default {
  name: "IndexPage",
  components: {
    HeaderPage,
    FooterPage,
  },
};
</script>

<style scoped>
#header {
  top: 0px;
  left: 0px;
  height: 260px;
  width: 100%;
  background-color: aquamarine;
  position: absolute;
}

#content {
  top: 200px;
  left: 0px;
  height: 1320px;
  width: 100%;
  background-color: chocolate;
  position: absolute;
}

#footer {
  top: 1520px;
  left: 0px;
  height: 64px;
  width: 100%;
  background-color: aquamarine;
  position: absolute;
}

h2 {
  font-size: 60pt;
  color: white;
  text-align: center;
}

button {
  background-color: white;
  border: 0;
  border-radius: 5%;
  width: 165px;
  height: 40px;
  text-size-adjust: 20pt;
}
.tf {
  border-radius: 5%;
}
</style>
