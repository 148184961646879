<template>
  <div>
    <table border="0" width="100%" height="70px">
      <tr>
        <td width="15%">
          <img src="./../assets/Cartoonlogo.png" width="250px" height="200px" />
        </td>
        <td width="15%"><router-link to="/" class="if">Home</router-link></td>
        <td width="15%">
          <router-link to="/cartoon" class="if">Cartoons</router-link>
        </td>
        <td width="15%">
          <router-link to="/about" class="if">About Us</router-link>
        </td>
        <td width="15%">
          <router-link to="/contact" class="if">Contact Us</router-link>
        </td>
      </tr>
    </table>

    <router-view />
  </div>
</template>

<script></script>

<style scoped>
.if {
  padding: 15px;
  border: 0px solid black;
  background-color: white;
  text-decoration: none;
  color: black;
  border-radius: 5%;
}
.if:hover {
  background-color: chocolate;
}
</style>
