<template>
  <div id="header">
    <HeaderPage />
  </div>

  <div id="content">
    <section>
      <h2 style="color: white">Mr Bean Cartoon</h2>

      <table border="0" width="100%" height="400px" style="color: white">
        <tr>
          <td width="50%" align="center">
            <iframe
              width="560"
              height="505"
              src="https://www.youtube.com/embed/G3TOTat8wbQ?si=byV6vMMjhaf6VjZL"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </td>
          <td width="50%" align="center">
            <h2>Mr Bean Details</h2>
            Mr. Bean is a British sitcom created by Rowan Atkinson and Richard
            Curtis, produced by Tiger Aspect Productions and starring Atkinson
            as the eponymous title character. The sitcom consists of 15 episodes
            that were co-written by Atkinson alongside Curtis and Robin
            Driscoll; the pilot episode was co-written by Ben Elton. The series
            originally aired on ITV, beginning with the pilot episode on 1
            January 1990 and ending with "The Best Bits of Mr. Bean" on 15
            December 1995. <br />
            Based on a character developed by Atkinson while he was studying for
            his master's degree at the University of Oxford, the series centres
            on Mr. Bean, described by Atkinson as "a child in a grown man's
            body", as he solves various problems presented by everyday tasks and
            often causes disruption in the process. The series has been
            influenced by physical comedy actors such as Jacques Tati and those
            from early silent films. <br /><br />
            During its original five-year run, Mr. Bean was met with widespread
            acclaim and attracted large television audiences. The series was
            viewed by 18.74 million viewers for the episode "The Trouble with
            Mr. Bean" and has received a number of international awards,
            including the Rose d'Or. The series has since been sold in 245
            territories worldwide. It has inspired an animated spin-off and two
            theatrical feature-length films along with Atkinson reprising his
            role as Mr. Bean for a performance at the London 2012 Summer
            Olympics opening ceremony, television commercials and several
            sketches for Comic Relief.
            <h3>watch here</h3>

            <router-link to="/looney"
              ><button class="button">Order Here</button></router-link
            >
          </td>
        </tr>
      </table>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <table border="0" width="100%" height="250px">
        <caption style="font-size: 40pt; color: white">
          <b> Episodes Available</b>
        </caption>
        <tr>
          <td width="16%" align="center">
            <h3 style="color: white">Season 1</h3>
            <img
              src="../assets/beanseason1.jpeg"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 1 Ep1</h3>
            <img
              src="../assets/beanseason1ep1.jpeg"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 2</h3>
            <img
              src="../assets/beanseason2.jpeg"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 2 Ep1</h3>
            <a href="picdetails2.html">
              <img
                src="../assets/beanseason2ep1.jpeg"
                width="200px"
                height="200px"
              />
            </a>
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 3</h3>
            <img
              src="../assets/beanseason3.jpeg"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 3 Ep1</h3>
            <img
              src="../assets/beanseason3ep1.jpeg"
              width="200px"
              height="200px"
            />
          </td>
        </tr>
      </table>
    </section>
  </div>

  <div id="footer">
    <FooterPage />
  </div>
</template>

<script>
import HeaderPage from "../components/HeaderPage.vue";
import FooterPage from "../components/FooterPage.vue";

export default {
  name: "DetailsPage",
  components: {
    HeaderPage,
    FooterPage,
  },
};
</script>

<style scoped>
#header {
  top: 0px;
  left: 0px;
  height: 260px;
  width: 100%;
  background-color: aquamarine;
  position: absolute;
}

#content {
  top: 250px;
  left: 0px;
  height: 1450px;
  width: 100%;
  background-color: chocolate;
  position: absolute;
}

#footer {
  top: 1660px;
  left: 0px;
  height: 64px;
  width: 100%;
  background-color: aquamarine;
  position: absolute;
}

h2 {
  font-size: 55pt;
  color: white;
  text-align: center;
}

button {
  background-color: white;
  border: 0;
  border-radius: 5%;
  width: 185px;
  height: 40px;
}
</style>
