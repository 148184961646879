<template>
  <div>
    <table border="0" width="100%" height="40px">
      <tr>
        <td>
          Copyright &copy; 2024. All right reserved. My Cartoon Site
          <br />
          Home | About Us | Contact Us
        </td>
      </tr>
    </table>
  </div>
</template>

<script></script>

<style scoped>
.mymum {
  font-size: 60pt;
  color: green;
}
</style>
