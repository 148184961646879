<template>
  <div id="header">
    <HeaderPage />
  </div>

  <div id="content">
    <section>
      <h2 style="color: white">Scooby_Doo Cartoon</h2>

      <table border="0" width="100%" height="400px" style="color: white">
        <tr>
          <td width="50%" align="center">
            <iframe
              width="560"
              height="505"
              src="https://www.youtube.com/embed/lG3H1-tKzmM?si=N6xmOSgYOs7rzLM0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </td>
          <td width="50%" align="center">
            <h2>Scooby_Doo Details</h2>
            Scooby-Doo is an American media franchise owned by Warner Bros.
            Entertainment and created in 1969 by writers Joe Ruby and Ken Spears
            through their animated series, Scooby-Doo, Where Are You!, for
            Hanna-Barbera (which was absorbed into Warner Bros. Animation in
            2001). The series features four teenagers: Fred Jones, Daphne Blake,
            Velma Dinkley, and Shaggy Rogers, and their talking Great Dane named
            Scooby-Doo, who solve mysteries involving supposedly supernatural
            creatures through a series of antics and missteps, while traveling
            using a brightly colored van called the "Mystery Machine". The
            franchise has several live-action films and <br />
            Scooby-Doo was originally broadcast on CBS from 1969 to 1976, when
            it moved to ABC. ABC aired various versions of Scooby-Doo until
            canceling it in 1986, and presented a spin-off featuring the
            characters as children called A Pup Named Scooby-Doo from 1988 until
            1991. Two Scooby-Doo reboots aired as part of Kids' WB on The WB and
            its successor The CW from 2002 until 2008. Further reboots were
            produced for Cartoon Network beginning in 2010 and continuing
            through 2018.<br />
            Repeats of the various Scooby-Doo series are frequently broadcast on
            Cartoon Network's sister channel Boomerang in the United States and
            other countries. The most recent Scooby-Doo series, Scooby-Doo and
            Guess Who?, premiered on June 27, 2019, as an original series on
            Boomerang's streaming service and later HBO Max.

            <h3>watch here</h3>

            <router-link to="/looney"
              ><button class="button">Order Here</button></router-link
            >
          </td>
        </tr>
      </table>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <table border="0" width="100%" height="250px">
        <caption style="font-size: 40pt; color: white">
          <b> Episodes Available</b>
        </caption>
        <tr>
          <td width="16%" align="center">
            <h3 style="color: white">Season 1</h3>
            <img src="../assets/scoseason1.jpeg" width="200px" height="200px" />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 1 Ep1</h3>
            <img
              src="../assets/scoobyseasonep1.jpeg"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 2</h3>
            <img
              src="../assets/scoobyseason2.jpeg"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 2 Ep1</h3>
            <a href="picdetails2.html">
              <img
                src="../assets/scoobyseason2ep1.jpeg"
                width="200px"
                height="200px"
              />
            </a>
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 3</h3>
            <img
              src="../assets/scoobyseason3.png"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 3 Ep1</h3>
            <img
              src="../assets/scoobyseason3ep1.png"
              width="200px"
              height="200px"
            />
          </td>
        </tr>
      </table>
    </section>
  </div>

  <div id="footer">
    <FooterPage />
  </div>
</template>

<script>
import HeaderPage from "../components/HeaderPage.vue";
import FooterPage from "../components/FooterPage.vue";

export default {
  name: "DetailsPage",
  components: {
    HeaderPage,
    FooterPage,
  },
};
</script>

<style scoped>
#header {
  top: 0px;
  left: 0px;
  height: 260px;
  width: 100%;
  background-color: aquamarine;
  position: absolute;
}

#content {
  top: 250px;
  left: 0px;
  height: 1250px;
  width: 100%;
  background-color: chocolate;
  position: absolute;
}

#footer {
  top: 1500px;
  left: 0px;
  height: 64px;
  width: 100%;
  background-color: aquamarine;
  position: absolute;
}

h2 {
  font-size: 35pt;
  color: white;
  text-align: center;
}

button {
  background-color: white;
  border: 0;
  border-radius: 5%;
  width: 185px;
  height: 40px;
}
</style>
