<template>
  <div id="header">
    <HeaderPage />
  </div>

  <div id="content">
    <section>
      <h2 style="color: white">Looney Tunes Cartoon</h2>

      <table border="0" width="100%" height="400px" style="color: white">
        <tr>
          <td width="50%" align="center">
            <iframe
              width="560"
              height="505"
              src="https://www.youtube.com/embed/laVF1fvcdGE?si=SflSdHSG3pAJnFBu"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </td>
          <td width="50%" align="center">
            <h2>Looney Tunes Details</h2>
            Looney Tunes is an American animated franchise produced and
            distributed by Warner Bros. It began as a series of short films that
            originally ran from 1930 to 1969, along with its partner series
            Merrie Melodies, during the golden age of American animation.
            Following a revival in the late 1970s, new shorts were released as
            recently as 2014. The two series introduced a large cast of
            characters, including Bugs Bunny, Daffy Duck, and Porky Pig.
            <br /><br />
            The term Looney Tunes has since been expanded to also refer to the
            characters themselves. Looney Tunes and Merrie Melodies were
            initially produced by Leon Schlesinger and animators Hugh Harman and
            Rudolf Ising from 1930 to 1933. Schlesinger assumed full production
            from 1933 until he sold his studio to Warner Bros. in 1944, after
            which it was renamed Warner Bros. Cartoons. <br /><br />The Looney
            Tunes title was inspired by that of Walt Disney's Silly Symphonies.
            The shorts initially showcased musical compositions owned by
            Warner's music publishing interests through the adventures of such
            characters as Bosko and Buddy. However, the shorts gained a higher
            profile upon the debuts of directors Tex Avery, Friz Freleng, Chuck
            Jones, and Bob Clampett and voice actor Mel Blanc later in the
            decade. Porky Pig and Daffy Duck became the featured Looney Tunes
            characters, while Merrie Melodies featured one-shot cartoons and
            minor recurring characters.
            <h3>watch here</h3>

            <router-link to="/looney"
              ><button class="button">Order Here</button></router-link
            >
          </td>
        </tr>
      </table>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <table border="0" width="100%" height="250px">
        <caption style="font-size: 40pt; color: white">
          <b> Episodes Available</b>
        </caption>
        <tr>
          <td width="16%" align="center">
            <h3 style="color: white">Season 1</h3>
            <img
              src="../assets/looneyseason1.jpeg"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 1 Ep1</h3>
            <img
              src="../assets/looneyseason1ep1.jpeg"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 2</h3>
            <img
              src="../assets/looneyseason2.jpeg"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 2 Ep1</h3>

            <img
              src="../assets/looneyseason2ep1.jpeg"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 3</h3>
            <img
              src="../assets/looneyseason3.jpeg"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 3 Ep1</h3>
            <img
              src="../assets/looneyseason3ep1.jpeg"
              width="200px"
              height="200px"
            />
          </td>
        </tr>
      </table>
    </section>
  </div>

  <div id="footer">
    <FooterPage />
  </div>
</template>

<script>
import HeaderPage from "../components/HeaderPage.vue";
import FooterPage from "../components/FooterPage.vue";

export default {
  name: "DetailsPage",
  components: {
    HeaderPage,
    FooterPage,
  },
};
</script>

<style scoped>
#header {
  top: 0px;
  left: 0px;
  height: 260px;
  width: 100%;
  background-color: aquamarine;
  position: absolute;
}

#content {
  top: 250px;
  left: 0px;
  height: 1250px;
  width: 100%;
  background-color: chocolate;
  position: absolute;
}

#footer {
  top: 1500px;
  left: 0px;
  height: 64px;
  width: 100%;
  background-color: aquamarine;
  position: absolute;
}

h2 {
  font-size: 35pt;
  color: white;
  text-align: center;
}

button {
  background-color: white;
  border: 0;
  border-radius: 5%;
  width: 185px;
  height: 40px;
}
</style>
