import { createWebHistory, createRouter } from "vue-router";

import Index from "./components/IndexPage.vue";
import Cartoon from "./components/CartoonPage.vue";
import Contact from "./components/ContactPage.vue";
import About from "./components/AboutPage.vue";
import Details from "./components/DetailsPage";
import Details2 from "./components/DetailsPage2.vue";
import Details3 from "./components/DetailsPage3.vue";
import Details4 from "./components/DetailsPage4.vue";
import Details5 from "./components/DetailsPage5.vue";
import Details6 from "./components/DetailsPage6.vue";
import Details7 from "./components/DetailsPage7.vue";
import Details8 from "./components/DetailsPage8.vue";
import Details9 from "./components/DetailsPage9.vue";
import Details10 from "./components/DetailsPage10.vue";
import LooneyPage from "./components/LooneyPage.vue";

const routes = [
  { name: "Index", path: "/", component: Index },
  { name: "Cartoon", path: "/cartoon", component: Cartoon },
  { name: "Contact", path: "/contact", component: Contact },
  { name: "About", path: "/about", component: About },
  { name: "Details", path: "/details", component: Details },
  { name: "Details2", path: "/details2", component: Details2 },
  { name: "Details3", path: "/details3", component: Details3 },
  { name: "Details4", path: "/details4", component: Details4 },
  { name: "Details5", path: "/details5", component: Details5 },
  { name: "Details6", path: "/details6", component: Details6 },
  { name: "Details7", path: "/details7", component: Details7 },
  { name: "Details8", path: "/details8", component: Details8 },
  { name: "looney", path: "/looney", component: LooneyPage },
  { name: "Details9", path: "/details9", component: Details9 },
  { name: "Details10", path: "/details10", component: Details10 },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
