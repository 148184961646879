<template>
  <div>
    <div id="header">
      <HeaderPage />
    </div>

    <div id="content">
      <section>
        <table border="0" width="100%" height="700px">
          <caption style="font-size: 25pt; color: white">
            <b>All Cartoons Available</b>
          </caption>
          <tr>
            <td>
              <table border="0" width="100%">
                <tr>
                  <td align="center" style="font-size: 25pt; color: white">
                    <b> Teen Titans Go</b>
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    <img
                      class="tf"
                      src="./../assets/teenlogo.jpeg"
                      width="300px"
                      height="250px"
                    />
                  </td>
                </tr>
                <tr>
                  <td align="center" style="font-size: 20pt; color: white">
                    <b> Get Here </b>
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    <router-link to="/details4"
                      ><button class="button">View Details</button></router-link
                    >
                  </td>
                </tr>
              </table>
            </td>
            <td>
              <table border="0" width="100%">
                <tr>
                  <td align="center" style="font-size: 25pt; color: white">
                    <b> Ninjago </b>
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    <img
                      class="tf"
                      src="../assets/ninjago.jpeg"
                      width="300px"
                      height="250px"
                    />
                  </td>
                </tr>
                <tr>
                  <td align="center" style="font-size: 20pt; color: white">
                    <b>Get Here</b>
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    <router-link to="/details5"
                      ><button class="button">View Details</button></router-link
                    >
                  </td>
                </tr>
              </table>
            </td>
            <td>
              <table border="0" width="100%">
                <tr>
                  <td align="center" style="font-size: 25pt; color: white">
                    <b> PJ Masks </b>
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    <img
                      class="tf"
                      src="./../assets/pjmaskslogo.jpeg"
                      width="300px"
                      height="250px"
                    />
                  </td>
                </tr>
                <tr>
                  <td align="center" style="font-size: 20pt; color: white">
                    <b> Get Here </b>
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    <router-link to="/details6"
                      ><button class="button">View Details</button></router-link
                    >
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <td>
            <table border="0" width="100%">
              <tr>
                <td align="center" style="font-size: 25pt; color: white">
                  <b> Casagrandes </b>
                </td>
              </tr>
              <tr>
                <td align="center">
                  <img
                    class="tf"
                    src="./../assets/casagrandeslogo.jpeg"
                    width="300px"
                    height="250px"
                  />
                </td>
              </tr>
              <tr>
                <td align="center" style="font-size: 20pt; color: white">
                  <b> Get Here</b>
                </td>
              </tr>
              <tr>
                <td align="center">
                  <router-link to="/details7"
                    ><button class="button">View Details</button></router-link
                  >
                </td>
              </tr>
            </table>
          </td>
          <td>
            <table border="0" width="100%">
              <tr>
                <td align="center" style="font-size: 25pt; color: white">
                  <b> Looney Tunes </b>
                </td>
              </tr>
              <tr>
                <td align="center">
                  <img
                    class="tf"
                    src="./../assets/looneylogo.jpeg"
                    width="300px"
                    height="250px"
                  />
                </td>
              </tr>
              <tr>
                <td align="center" style="font-size: 20pt; color: white">
                  <b> Get Here </b>
                </td>
              </tr>
              <tr>
                <td align="center">
                  <router-link to="/details8"
                    ><button class="button">View Details</button></router-link
                  >
                </td>
              </tr>
            </table>
          </td>
          <td>
            <table border="0" width="100%">
              <tr>
                <td align="center" style="font-size: 25pt; color: white">
                  <b> Adventure Time</b>
                </td>
              </tr>
              <tr>
                <td align="center">
                  <img
                    class="tf"
                    src="./../assets/adventurelogo.jpeg"
                    width="300px"
                    height="250px"
                  />
                </td>
              </tr>
              <tr>
                <td align="center" style="font-size: 20pt; color: white">
                  <b> Get Here </b>
                </td>
              </tr>
              <tr>
                <td align="center">
                  <router-link to="/details9"
                    ><button class="button">View Details</button></router-link
                  >
                </td>
              </tr>
            </table>
          </td>
          <tr>
            <td>
              <table border="0" width="100%">
                <tr>
                  <td align="center" style="font-size: 25pt; color: white">
                    <b> Mr Bean </b>
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    <img
                      class="tf"
                      src="./../assets/mrbean.png"
                      width="300px"
                      height="250px"
                    />
                  </td>
                </tr>
                <tr>
                  <td align="center" style="font-size: 20pt; color: white">
                    <b> Get Here </b>
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    <router-link to="/details2"
                      ><button class="button">View Details</button></router-link
                    >
                  </td>
                </tr>
              </table>
            </td>
            <td>
              <table border="0" width="100%">
                <tr>
                  <td align="center" style="font-size: 25pt; color: white">
                    <b> Scooby-Doo </b>
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    <img
                      class="tf"
                      src="./../assets/scooby.jpeg"
                      width="300px"
                      height="250px"
                    />
                  </td>
                </tr>
                <tr>
                  <td align="center" style="font-size: 20pt; color: white">
                    <b> Get Here </b>
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    <router-link to="/details"
                      ><button class="button">View Details</button></router-link
                    >
                  </td>
                </tr>
              </table>
            </td>
            <td>
              <table border="0" width="100%">
                <tr>
                  <td align="center" style="font-size: 25pt; color: white">
                    <b> Ben 10</b>
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    <img
                      class="tf"
                      src="./../assets/ben.jpeg"
                      width="300px"
                      height="250px"
                    />
                  </td>
                </tr>
                <tr>
                  <td align="center" style="font-size: 20pt; color: white">
                    <b> Get Here </b>
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    <button class="button">View Details</button>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <td>
            <table border="0" width="100%">
              <tr>
                <td align="center" style="font-size: 25pt; color: white">
                  <b> SpongeBob </b>
                </td>
              </tr>
              <tr>
                <td align="center">
                  <img
                    class="tf"
                    src="./../assets/sponge.jpeg"
                    width="300px"
                    height="250px"
                  />
                </td>
              </tr>
              <tr>
                <td align="center" style="font-size: 20pt; color: white">
                  <b> Get Here </b>
                </td>
              </tr>
              <tr>
                <td align="center">
                  <button class="button">View Details</button>
                </td>
              </tr>
            </table>
          </td>
          <td>
            <table border="0" width="100%">
              <tr>
                <td align="center" style="font-size: 25pt; color: white">
                  <b> Tom And Jerry</b>
                </td>
              </tr>
              <tr>
                <td align="center">
                  <img
                    class="tf"
                    src="./../assets/tom.jpeg"
                    width="300px"
                    height="250px"
                  />
                </td>
              </tr>
              <tr>
                <td align="center" style="font-size: 20pt; color: white">
                  <b> Get Here </b>
                </td>
              </tr>
              <tr>
                <td align="center">
                  <button class="button">View Details</button>
                </td>
              </tr>
            </table>
          </td>
          <td>
            <table border="0" width="100%">
              <tr>
                <td align="center" style="font-size: 25pt; color: white">
                  <b> Powerpuff Girls </b>
                </td>
              </tr>
              <tr>
                <td align="center">
                  <img
                    class="tf"
                    src="./../assets/powergirls.jpeg"
                    width="300px"
                    height="250px"
                  />
                </td>
              </tr>
              <tr>
                <td align="center" style="font-size: 20pt; color: white">
                  <b> Get Here </b>
                </td>
              </tr>
              <tr>
                <td align="center">
                  <router-link to="/details3"
                    ><button class="button">View Details</button></router-link
                  >
                </td>
              </tr>
            </table>
          </td>
        </table>
      </section>
    </div>

    <div id="footer">
      <FooterPage />
    </div>
  </div>
</template>

<script>
import HeaderPage from "../components/HeaderPage.vue";
import FooterPage from "../components/FooterPage.vue";

export default {
  name: "loginPage",
  components: {
    HeaderPage,
    FooterPage,
  },
};
</script>

<style scoped>
#header {
  top: 0px;
  left: 0px;
  height: 260px;
  width: 100%;
  background-color: aquamarine;
  position: absolute;
}

#content {
  top: 200px;
  left: 0px;
  height: 1620px;
  width: 100%;
  background-color: chocolate;
  position: absolute;
}

#footer {
  top: 1820px;
  left: 0px;
  height: 64px;
  width: 100%;
  background-color: aquamarine;
  position: absolute;
}

button {
  background-color: white;
  border: 0;
  border-radius: 5%;
  width: 175px;
  height: 40px;
}
.tf {
  border-radius: 5%;
}
</style>
