<template>
  <div id="header">
    <HeaderPage />
  </div>

  <div id="content">
    <section>
      <h2 style="color: white">Ben 10 Cartoon</h2>

      <table border="0" width="100%" height="400px" style="color: white">
        <tr>
          <td width="50%" align="center">
            <iframe
              width="560"
              height="505"
              src="https://www.youtube.com/embed/xO99YrUnLqg?si=d-vgfs6kh7-b63i1"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </td>
          <td width="50%" align="center">
            <h2>Ben 10 Details</h2>
            Ben 10 is an American media franchise conceived by Man of Action,
            produced by Cartoon Network Studios, and owned by Warner Bros.
            Entertainment. The franchise revolves around a young boy named Ben
            Tennyson, who discovers the Omnitrix — a high-tech, extraterrestrial
            device shaped like a wristwatch. This remarkable gadget contains the
            DNA of various alien species, allowing Ben to transform into them at
            will. <br /><br />
            Initially, the Omnitrix features ten alien transformations, but over
            time, Ben gains the ability to unlock additional species. The
            franchise has garnered considerable critical acclaim, securing three
            Emmy Awards. It encompasses five primary television series, five
            feature films, and numerous video games. It ranks as Cartoon
            Network's second longest existing franchise. Furthermore, Ben 10 has
            inspired a line of toys produced initially by Bandai for the
            franchise's first four series and later by Playmates Toys for the
            reboot. <br /><br />
            Overall, the franchise has amassed over $6 billion in retail
            sales.[1] On February 17, 2021, Cartoon Network announced three
            44-minute specials slated for April 2021, titled Ben 10,010, Ben Gen
            10, and Alien X-Tinction. These specials serve as both the series
            finale for the 2016 reboot and a crossover event involving the first
            four television series in the franchise.
            <h3>watch here</h3>

            <router-link to="/looney"
              ><button class="button">Order Here</button></router-link
            >
          </td>
        </tr>
      </table>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <table border="0" width="100%" height="250px">
        <caption style="font-size: 40pt; color: white">
          <b> Episodes Available</b>
        </caption>
        <tr>
          <td width="16%" align="center">
            <h3 style="color: white">Season 1</h3>
            <img src="../assets/benseason1.jpeg" width="200px" height="200px" />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 1 Ep1</h3>
            <img
              src="../assets/benseason1ep1.jpeg"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 2</h3>
            <img src="../assets/benseason2.jpeg" width="200px" height="200px" />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 2 Ep1</h3>

            <img
              src="../assets/benseason2ep1.jpeg"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 3</h3>
            <img src="../assets/benseason3.jpeg" width="200px" height="200px" />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 3 Ep1</h3>
            <img
              src="../assets/benseason3ep1.jpeg"
              width="200px"
              height="200px"
            />
          </td>
        </tr>
      </table>
    </section>
  </div>

  <div id="footer">
    <FooterPage />
  </div>
</template>

<script>
import HeaderPage from "../components/HeaderPage.vue";
import FooterPage from "../components/FooterPage.vue";

export default {
  name: "DetailsPage",
  components: {
    HeaderPage,
    FooterPage,
  },
};
</script>

<style scoped>
#header {
  top: 0px;
  left: 0px;
  height: 260px;
  width: 100%;
  background-color: aquamarine;
  position: absolute;
}

#content {
  top: 250px;
  left: 0px;
  height: 1430px;
  width: 100%;
  background-color: chocolate;
  position: absolute;
}

#footer {
  top: 1680px;
  left: 0px;
  height: 64px;
  width: 100%;
  background-color: aquamarine;
  position: absolute;
}

h2 {
  font-size: 55pt;
  color: white;
  text-align: center;
}

button {
  background-color: white;
  border: 0;
  border-radius: 5%;
  width: 185px;
  height: 40px;
}
</style>
