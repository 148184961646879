<template>
  <div id="header">
    <HeaderPage />
  </div>

  <div id="content">
    <section>
      <h2 style="color: white">Casagrandes Cartoon</h2>

      <table border="0" width="100%" height="400px" style="color: white">
        <tr>
          <td width="50%" align="center">
            <iframe
              width="560"
              height="505"
              src="https://www.youtube.com/embed/3Z6ATox-MXo?si=U2nTnFkyULCcYY_q"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </td>
          <td width="50%" align="center">
            <h2>Casagrandes Details</h2>
            The Casagrandes is an American animated comedy television series
            developed by Michael Rubiner and Miguel Puga that aired on
            Nickelodeon from October 14, 2019 to September 30, 2022. It is a
            spin-off of The Loud House and the second television series in the
            overall franchise, which follows the adventures of Ronnie Anne, her
            brother Bobby Santiago, and their family living in the fictional
            Great Lakes City. An animated feature film which serves as a
            continuation and series finale to the show was released on Netflix
            on March 22, 2024. <br /><br />Premise Having moved to Great Lakes
            City back in The Loud House episode "The Loudest Mission: Relative
            Chaos", Ronnie Anne Santiago, her older brother Bobby, and their
            mother Maria move in with their extended family called the
            Casagrandes consisting of grandparents Hector and Rosa, aunt and
            uncle Frida and Carlos, their cousins Carlota, C.J., Carl, and
            Carlitos, and their pets Lalo and Sergio. <br /><br />Maria reason
            of moving her children to her relatives because she wants someone to
            look after her children while she works. Ronnie Anne meets new
            friends like Sid Chang, expresses more about her relatives, and
            explores the endless possibilities in Great Lakes City. Bobby helps
            Hector run the mercado as he discovers similarities with his
            family's quirky neighbors.
            <h3>watch here</h3>

            <router-link to="/looney"
              ><button class="button">Order Here</button></router-link
            >
          </td>
        </tr>
      </table>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <table border="0" width="100%" height="250px">
        <caption style="font-size: 40pt; color: white">
          <b> Episodes Available</b>
        </caption>
        <tr>
          <td width="16%" align="center">
            <h3 style="color: white">Season 1</h3>
            <img
              src="../assets/casaseason1.jpeg"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 1 Ep1</h3>
            <img
              src="../assets/casaseason1ep1.jpeg"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 2</h3>
            <img
              src="../assets/casaseason2.jpeg"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 2 Ep1</h3>

            <img
              src="../assets/casaseason2ep1.jpeg"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 3</h3>
            <img
              src="../assets/casaseason3.jpeg"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 3 Ep1</h3>
            <img
              src="../assets/casaseason3ep1.jpeg"
              width="200px"
              height="200px"
            />
          </td>
        </tr>
      </table>
    </section>
  </div>

  <div id="footer">
    <FooterPage />
  </div>
</template>

<script>
import HeaderPage from "../components/HeaderPage.vue";
import FooterPage from "../components/FooterPage.vue";

export default {
  name: "DetailsPage",
  components: {
    HeaderPage,
    FooterPage,
  },
};
</script>

<style scoped>
#header {
  top: 0px;
  left: 0px;
  height: 260px;
  width: 100%;
  background-color: aquamarine;
  position: absolute;
}

#content {
  top: 250px;
  left: 0px;
  height: 1250px;
  width: 100%;
  background-color: chocolate;
  position: absolute;
}

#footer {
  top: 1500px;
  left: 0px;
  height: 64px;
  width: 100%;
  background-color: aquamarine;
  position: absolute;
}

h2 {
  font-size: 35pt;
  color: white;
  text-align: center;
}

button {
  background-color: white;
  border: 0;
  border-radius: 5%;
  width: 185px;
  height: 40px;
}
</style>
