<template>
  <div id="header">
    <HeaderPage />
  </div>

  <div id="content">
    <section>
      <h2 style="color: white">Ninjago Cartoon</h2>

      <table border="0" width="100%" height="400px" style="color: white">
        <tr>
          <td width="50%" align="center">
            <iframe
              width="560"
              height="505"
              src="https://www.youtube.com/embed/MIIlF9fEZaM?si=RNlcKGw0ySIsaKwJ"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </td>
          <td width="50%" align="center">
            <h2>Ninjago Details</h2>
            Ninjago (previously known as Ninjago: Masters of Spinjitzu until
            2019) is an animated epic-fantasy superhero television series
            produced by The Lego Group. It was created to coincide with the Lego
            Ninjago line of construction toys, which is based on the characters
            and events of the series. It centers on the fictional world of
            Ninjago, telling the story of a group of six teenage ninjas and
            their battles against the forces of evil. The series was created by
            Michael Hegner and Tommy Andreasen, two Danish film producers. The
            storyline was written by Kevin and Dan Hageman until the ninth
            season; their successor as the head writer was Bragi Schut.
            <br /><br />
            The show features a large ensemble cast of voice actors who had
            recurring roles in the series. The English-language voice cast was
            recorded in Canada for its entire run. The music was scored by
            composers Michael Kramer and Jay Vincent. The series was in
            continuous production for over a decade and celebrated its tenth
            anniversary on January 14, 2021. It began with two pilot episodes in
            January 2011, which were followed by two 13-episode seasons that
            aired from December 2011 to November 2012. Both the Lego theme and
            the series had an intended shelf life of three years with the second
            season planned as the original ending. <br />
            However, the success of the series and its product line led to the
            show continuing production, with thirteen more seasons, a special, a
            feature film adaptation, and a four-episode miniseries being
            released. Ninjago: Masters of Spinjitzu was produced in Copenhagen,
            Denmark, by Wil Film ApS for its first ten seasons. The production
            was relocated to WildBrain Studios in Canada for the eleventh
            season, and the series was retitled Ninjago. The series concluded on
            October 1, 2022, with the second part of the fifteenth season being
            released on Netflix in Australia and New Zealand.
            <h3>watch here</h3>

            <router-link to="/looney"
              ><button class="button">Order Here</button></router-link
            >
          </td>
        </tr>
      </table>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <table border="0" width="100%" height="250px">
        <caption style="font-size: 40pt; color: white">
          <b> Episodes Available</b>
        </caption>
        <tr>
          <td width="16%" align="center">
            <h3 style="color: white">Season 1</h3>
            <img
              src="../assets/ninjagoseason1.jpeg"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 1 Ep1</h3>
            <img
              src="../assets/ninjagoseason1ep1.jpeg"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 2</h3>
            <img
              src="../assets/ninjagoseason2.jpeg"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 2 Ep1</h3>

            <img
              src="../assets/ninjagoseason2ep1.jpeg"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 3</h3>
            <img
              src="../assets/ninjagoseason3.jpeg"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 3 Ep1</h3>
            <img
              src="../assets/ninjagoseason3ep1.jpeg"
              width="200px"
              height="200px"
            />
          </td>
        </tr>
      </table>
    </section>
  </div>

  <div id="footer">
    <FooterPage />
  </div>
</template>

<script>
import HeaderPage from "../components/HeaderPage.vue";
import FooterPage from "../components/FooterPage.vue";

export default {
  name: "DetailsPage",
  components: {
    HeaderPage,
    FooterPage,
  },
};
</script>

<style scoped>
#header {
  top: 0px;
  left: 0px;
  height: 260px;
  width: 100%;
  background-color: aquamarine;
  position: absolute;
}

#content {
  top: 250px;
  left: 0px;
  height: 1250px;
  width: 100%;
  background-color: chocolate;
  position: absolute;
}

#footer {
  top: 1500px;
  left: 0px;
  height: 64px;
  width: 100%;
  background-color: aquamarine;
  position: absolute;
}

h2 {
  font-size: 35pt;
  color: white;
  text-align: center;
}

button {
  background-color: white;
  border: 0;
  border-radius: 5%;
  width: 185px;
  height: 40px;
}
</style>
