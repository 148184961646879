<template>
  <div id="header">
    <HeaderPage />
  </div>

  <div id="content">
    <section>
      <h2>Cartoons Orders Form</h2>

      <form>
        <table border="0" align="center" width="650px">
          <tr>
            Order Request
            <hr width="25%" />
            PLEASE FILL THE FORM BELOW TO ORDER FOR YOUR CARTOONS.
            <br />
            <br />
            Fields marked with * are Mandatory
            <br />
          </tr>
        </table>
        <hr width="50%" />
        <table border="0" align="center" width="650px">
          <td>
            <label>Full Name *</label>
            <br />
            <input type="text" />
          </td>
          <td>
            <br />
            <label>Email *</label>
            <br />
            <input type="text" />
            <br />
          </td>
        </table>
        <table border="0" align="center" width="650px">
          <tr>
            <td>
              <label>Phone Number *</label>
              <br />
              <input type="text" />
            </td>
            <td>
              <br />
              <label>Location </label>
              <br />
              <input type="text" />
              <br /><br />
            </td>
          </tr>
        </table>
        <table border="0" align="center" width="650px">
          <tr>
            <td>
              <label>file depository email Address</label>
              <br />
              <textarea rows="7" cols="25"></textarea>
            </td>
            <td>
              <label align="center">Number of cartoons </label>
              <br />
              <select>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
                <option>11</option>
                <option>12</option>
              </select>
              <br /><br />

              <label align="left">Cartoon Name</label>
              <select>
                <option>teens titans go</option>
                <option>ninjago</option>
                <option>looneytunes</option>
                <option>Ben 10</option>
                <option>Tom and jerry</option>
                <option>Adventure time</option>
                <option>Scooby_doo</option>
                <option>Spongebob</option>
                <option>PJ Masks</option>
                <option>Casagrandes</option>
                <option>Mr Beans</option>
              </select>
            </td>
          </tr>
        </table>

        <table border="0" align="center" width="650px">
          <tr>
            <td>
              <br />
              <br />
              <button color="blue">Send Order</button>
            </td>
          </tr>
        </table>
      </form>
    </section>
  </div>

  <div id="footer">
    <FooterPage />
  </div>
</template>

<script>
import HeaderPage from "../components/HeaderPage.vue";
import FooterPage from "../components/FooterPage.vue";

export default {
  name: "DetailsPage",
  components: {
    HeaderPage,
    FooterPage,
  },
};
</script>

<style scoped>
#header {
  top: 0px;
  left: 0px;
  height: 260px;
  width: 100%;
  background-color: aquamarine;
  position: absolute;
}

#content {
  top: 250px;
  left: 0px;
  height: 820px;
  width: 100%;

  position: absolute;
}

#footer {
  top: 1072px;
  left: 0px;
  height: 80px;
  width: 100%;
  background-color: aquamarine;
  position: absolute;
}

h2 {
  font-size: 55pt;
  text-align: center;
}

button {
  background-color: blue;
  border: 0;
  border-radius: 5%;
  width: 165px;
  height: 40px;
  text-size-adjust: 20pt;
  color: white;
}
</style>
