<template>
  <div id="header">
    <HeaderPage />
  </div>

  <div id="content">
    <section>
      <h2 style="color: white">PowerPuff Girls Cartoon</h2>

      <table border="0" width="100%" height="400px" style="color: white">
        <tr>
          <td width="50%" align="center">
            <iframe
              width="560"
              height="505"
              src="https://www.youtube.com/embed/f7MiaSr-0ug?si=cBaMwyU5hsurvo4G"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </td>
          <td width="50%" align="center">
            <h2>PowerPuff Girls Details</h2>
            The Powerpuff Girls[b] is an American superhero animated television
            series created by animator Craig McCracken and produced by
            Hanna-Barbera (later Cartoon Network Studios) for Cartoon Network.
            The show centers on Blossom, Bubbles, and Buttercup, three
            kindergarten-aged girls with superpowers. They live in the fictional
            city of Townsville with their father and creator, a scientist named
            Professor Utonium, and are frequently called upon by the city's
            mayor to help fight nearby criminals and other enemies using their
            powers. <br />
            <br />While attending his second year at CalArts in 1992, McCracken
            created a short film, Whoopass Stew!, about a trio of child
            superheroes known as the Whoopass Girls, which was only shown at
            festivals. Following the name change to The Powerpuff Girls,
            McCracken submitted his student film to Cartoon Network, who aired
            the series' refined pilot, "Meat Fuzzy Lumpkins", in its animation
            showcase program World Premiere Toons on February 20, 1995, along
            with its follow-up, "Crime 101", on January 28, 1996. Network
            executives gave McCracken the greenlight for a full series, which
            debuted as a Cartoon Cartoon on November 18, 1998. The Powerpuff
            Girls aired on Cartoon Network for six seasons, three specials, and
            a feature film, with the final episode airing on March 25, 2005.
            <br /><br />
            A total of 78 episodes were produced, in addition to the two pilots,
            a Christmas special, the film, a tenth anniversary special, and a
            special episode in computer animation. Various spin-off media
            include an anime, three CD soundtracks, a home video collection,
            comic books, a series of video games, a 2016 reboot series, and an
            upcoming second reboot, as well as various licensed merchandise. The
            series has been nominated for six Emmy Awards, nine Annie Awards,
            and a Kids' Choice Award during its run.
            <h3>watch here</h3>

            <router-link to="/looney"
              ><button class="button">Order Here</button></router-link
            >
          </td>
        </tr>
      </table>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <table border="0" width="100%" height="250px">
        <caption style="font-size: 40pt; color: white">
          <b> Episodes Available</b>
        </caption>
        <tr>
          <td width="16%" align="center">
            <h3 style="color: white">Season 1</h3>
            <img
              src="../assets/powerseason1.png"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 1 Ep1</h3>
            <img
              src="../assets/powerseason1ep1.jpeg"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 2</h3>
            <img
              src="../assets/powerseason2.jpeg"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 2 Ep1</h3>

            <img
              src="../assets/powerseason2ep1.png"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 3</h3>
            <img
              src="../assets/powerseason3.jpeg"
              width="200px"
              height="200px"
            />
          </td>
          <td width="16%" align="center">
            <h3 style="color: white">Season 3 Ep1</h3>
            <img
              src="../assets/powerseason3ep1.jpeg"
              width="200px"
              height="200px"
            />
          </td>
        </tr>
      </table>
    </section>
  </div>

  <div id="footer">
    <FooterPage />
  </div>
</template>

<script>
import HeaderPage from "../components/HeaderPage.vue";
import FooterPage from "../components/FooterPage.vue";

export default {
  name: "DetailsPage",
  components: {
    HeaderPage,
    FooterPage,
  },
};
</script>

<style scoped>
#header {
  top: 0px;
  left: 0px;
  height: 260px;
  width: 100%;
  background-color: aquamarine;
  position: absolute;
}

#content {
  top: 250px;
  left: 0px;
  height: 1270px;
  width: 100%;
  background-color: chocolate;
  position: absolute;
}

#footer {
  top: 1510px;
  left: 0px;
  height: 64px;
  width: 100%;
  background-color: aquamarine;
  position: absolute;
}

h2 {
  font-size: 35pt;
  color: white;
  text-align: center;
}

button {
  background-color: white;
  border: 0;
  border-radius: 5%;
  width: 185px;
  height: 40px;
}
</style>
