<template>
  <div>
    <div id="header">
      <HeaderPage />
    </div>

    <div id="content">
      <section>
        <table border="0" width="100%" height="400px" style="color: white">
          <tr>
            <td width="50%" align="center">
              <p>
                <b>GOOGLE MAP</b>
              </p>
            </td>
            <td width="50%" align="center">
              <h3>CUSTOMER SERVICE FORM</h3>
              <form method="post" align="center">
                <table border="0" width="100%" height="370px">
                  <tr>
                    <td>
                      <label>Name:</label>
                      <br />
                      <input type="text" name="name" id="name" class="button" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Email:</label>
                      <br />
                      <input
                        type="text"
                        name="email"
                        id="email"
                        class="button"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>House Request:</label>
                      <br />
                      <input
                        type="text"
                        name="house"
                        id="house"
                        class="button"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Complaints:</label>
                      <br />
                      <input type="text" name="comp" id="comp" class="button" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <button class="button">Submit</button>
                    </td>
                  </tr>
                </table>
              </form>
            </td>
          </tr>
        </table>

        <table border="0" width="100%" height="150px" style="color: white">
          <tr>
            <td width="25%" align="center">
              Office Address
              <p>95a, lagos Road Agric Bus stop, Ikorodu Lagos.</p>
            </td>
            <td width="25%" align="center">
              Email Address
              <p>abdul07shutdown@gmail.com</p>
            </td>
            <td width="25%" align="center">
              Phone Number
              <p>08147552654</p>
            </td>
            <td width="25%" align="center">
              Social Medias
              <p>
                <img
                  src="./../assets/facebook.jpeg"
                  width="50px"
                  height="50px"
                />
                <img
                  src="./../assets/instagram.jpeg"
                  width="50px"
                  height="50px"
                />
                <img src="./../assets/twitter.png" width="50px" height="50px" />
              </p>
            </td>
          </tr>
        </table>
      </section>
    </div>

    <div id="footer">
      <FooterPage />
    </div>
  </div>
</template>

<script>
import HeaderPage from "../components/HeaderPage.vue";
import FooterPage from "../components/FooterPage.vue";

export default {
  name: "AboutPage",
  components: {
    HeaderPage,
    FooterPage,
  },
};
</script>

<style scoped>
#header {
  top: 0px;
  left: 0px;
  height: 260px;
  width: 100%;
  background-color: aquamarine;
  position: absolute;
}

#content {
  top: 200px;
  left: 0px;
  height: 600px;
  width: 100%;
  background-color: chocolate;
  position: absolute;
}

#footer {
  top: 800px;
  left: 0px;
  height: 64px;
  width: 100%;
  background-color: aquamarine;
  position: absolute;
}

h2 {
  font-size: 60pt;
  color: white;
  text-align: center;
}

label {
  color: white;
}
</style>
